import "./Projects.scss";
import Card from "../../components/Card/Card";

import efbDescentCalcLogo from "../../assets/images/efb-descent-calculator-logo.svg";


export default function Projects() {
    return (
        <div className={"projects-container"}>
            <section>
                <h3 className={"is-size-3 secondary-font has-text-centered"}>
                    <span className={"has-text-primary"}>Professional</span> Projects
                </h3>
                <p className={"mb-5 has-text-centered"}>All the projects I have worked on during my Career</p>
                <div className={"project-cards-container"}>
                    {/* draftss.com */}
                    <Card>
                        <div className={"project"}>
                            <div className={"is-flex is-flex-direction-row is-align-items-center mb-4"}>
                                <img src="https://logo.clearbit.com/draftss.com" alt="logo" className={"logo mr-4"}/>
                                <h4 className={"is-size-4 m-0"}>Draftss</h4>
                            </div>
                            <p className={"project-description"}>
                                <span className={"has-text-weight-bold"}>Draftss.com</span> is an unlimited graphic
                                design and development subscription service providing logo, branding, illustration,
                                website design & development at one place.
                            </p>
                            {/*<p className={"has-text-weight-bold"}>June 2022 - April 2023</p>*/}
                            <p className={"is-size-6"}>
                                Website: <a href="https://draftss.com" target={"_blank"} rel={"external noreferrer"}>
                                https://draftss.com</a>
                            </p>
                        </div>
                    </Card>

                    {/* prospectss.com */}
                    <Card>
                        <div className={"project"}>
                            <div className={"is-flex is-flex-direction-row is-align-items-center mb-4"}>
                                <img src="https://logo.clearbit.com/prospectss.com" alt="logo" className={"logo mr-4"}/>
                                <h4 className={"is-size-4 m-0"}>Prospectss</h4>
                            </div>
                            <p className={"project-description"}>
                                <span className={"has-text-weight-bold"}>Prospectss.com</span> is a lead generation
                                company that provides tools for managing leads, tracking campaigns, and
                                collaborating with teams.
                            </p>
                            {/*<p className={"has-text-weight-bold"}>June 2022 - April 2023</p>*/}
                            <p className={"is-size-6"}>
                                Website: <a href="https://prospectss.com" target={"_blank"} rel={"external noreferrer"}>
                                https://prospectss.com</a>
                            </p>
                        </div>
                    </Card>

                    {/* abun.com */}
                    <Card>
                        <div className={"project"}>
                            <div className={"is-flex is-flex-direction-row is-align-items-center mb-4"}>
                                <img src="https://logo.clearbit.com/abun.com" alt="logo" className={"logo mr-4"}/>
                                <h4 className={"is-size-4 m-0"}>Abun</h4>
                            </div>
                            <p className={"project-description"}>
                                <span className={"has-text-weight-bold"}>Abun.com</span> allows you to automate your
                                blog post generation with high-quality, SEO-optimized AI content that drives
                                organic traffic to your website.
                            </p>
                            {/*<p className={"has-text-weight-bold"}>April 2023 - Present</p>*/}
                            <p className={"is-size-6"}>
                                Website: <a href="https://abun.com" target={"_blank"} rel={"external noreferrer"}>
                                https://abun.com</a>
                            </p>
                        </div>
                    </Card>
                </div>
            </section>
            <hr className={"dropdown-divider w-100 mt-6 mb-6"}/>
            <section>
                <h3 className={"is-size-3 secondary-font has-text-centered"}>
                    <span className={"has-text-primary"}>Personal</span> Projects
                </h3>
                <p className={"mb-5 has-text-centered"}>All the side projects / ideas I have worked on till now</p>
                <div className={"project-cards-container"}>
                    {/* MSFS24 EFB Descent Calculator */}
                    <Card>
                        <div className={"project"}>
                            <div className={"is-flex is-flex-direction-row is-align-items-center mb-4"}>
                                <img src={efbDescentCalcLogo} alt="logo" className={"logo-no-round mr-4"}/>
                                <h4 className={"is-size-4 m-0"}>MSFS24 EFB Descent Calculator</h4>
                            </div>
                            <p className={"project-description"}>
                                A simple descent calculator for Microsoft Flight Simulator 2024 which can be added to
                                the built-in EFB as an app.
                            </p>
                            {/*<p className={"has-text-weight-bold"}>June 2022 - April 2023</p>*/}
                            <p className={"is-size-6"}>
                                Link: <a href="https://github.com/Arun-N/msfs24-efb-descent-calculator-app"
                                         target={"_blank"}
                                         rel={"external noreferrer"}>
                                https://github.com/Arun-N/msfs24-efb-descent-calculator-app</a>
                            </p>
                        </div>
                    </Card>
                </div>
            </section>
        </div>
)
}