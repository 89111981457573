import "./Home.scss"
import Card from "../../components/Card/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LinkButton from "../../components/LinkButton/LinkButton";
import {pageURL} from "../routes";

export default function Home() {
    return (
        <div className={"home-container"}>
            <section className={"intro-section mb-6"}>
                <h2 className={"is-size-2 secondary-font has-text-weight-bold has-text-centered"}>
                    Hi! I'm <span className={"has-text-primary"}>Arun Nair</span>
                </h2>
                <p className={"has-text-centered mt-4 is-size-5"}>
                    I'm a Full-Stack Developer specializing in building AI powered web-based services utilizing <br/>
                    <b>Django</b>, <b>React</b>, <b>Kubernetes</b>, and <b>Langchain</b>.
                </p>
                <LinkButton to={pageURL['projects']} theme={"primary"} className={"mt-6 is-rounded"}>
                    Check out My Portfolio!
                </LinkButton>
            </section>
            <section className={"stats-section mt-6 mb-6"}>
                {/* ----------- Experience (years) ----------- */}
                <Card>
                    <h5 className={"is-size-5 secondary-font"}>
                        Working Professionally
                    </h5>
                    <p className={"is-size-4"}>5+ Years</p>
                </Card>
                {/* ----------- Experience (major projects) ----------- */}
                <Card>
                    <h5 className={"is-size-5 secondary-font"}>
                        Major Projects
                    </h5>
                    <p className={"is-size-4"}>3</p>
                </Card>
            </section>
            <hr className={"dropdown-divider w-100 mt-6 mb-6"}/>
            <section className={"contact-section"}>
                <h4 className={"is-size-4 secondary-font has-text-weight-bold has-text-centered"}>
                    Contact Me At
                </h4>
                <a href="mailto:me@arunnair.dev"
                   target={"_blank"}
                   rel={"noreferrer"}
                   className={"has-text-centered has-text-primary is-size-5"}>
                    me@arunnair.dev
                </a>
                <div className={"contact-section-links mt-6"}>
                    {/* linkedin */}
                    <a href="https://www.linkedin.com/in/arun-janardhanan-nair/"
                       target={"_blank"}
                       rel={"noreferrer"}
                       className={"linkedin"}>
                        <FontAwesomeIcon icon={["fab", "linkedin"]}/>
                    </a>
                    {/* gitlab */}
                    <a href="https://github.com/Arun-N"
                       target={"_blank"}
                       rel={"noreferrer"}
                       className={"github"}>
                        <FontAwesomeIcon icon={["fab", "github"]}/>
                    </a>
                    {/* github */}
                    <a href="https://gitlab.com/Arun-N"
                       target={"_blank"}
                       rel={"noreferrer"}
                       className={"gitlab"}>
                        <FontAwesomeIcon icon={["fab", "gitlab"]}/>
                    </a>
                </div>
            </section>
        </div>
    )
}